import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import ProtectedComponent from '@/components/Protected'
import { Button } from '@/components/ui/Button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/Dialog'
import Field from '@/components/ui/Field'
import Switch from '@/components/ui/Switch'

import { ICreateAccessCode } from '@/types/access-code/access-code.interface'

import { errorCatch } from '@/api/error'

import api from '@/api'

export const CreateAccessCode = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [type, setType] = useState<'individual' | 'group'>('individual')

  const queryClient = useQueryClient()

  const { mutate, isPending } = useMutation({
    mutationKey: ['create-access-code'],
    mutationFn: async (data: ICreateAccessCode) => {
      const resp = await api.post(
        `/admin/access-codes`,
        type === 'individual' ? { code: data.code } : { count: data.count }
      )

      return resp.data
    },
    onSuccess: async () => {
      reset()
      queryClient.refetchQueries()
      setIsOpen(false)
      toast.success(
        type === 'individual' ? 'Access code created' : 'Access codes created'
      )
    },
    onError: error => {
      toast.error(errorCatch(error) || 'An error occurred')
    }
  })

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<ICreateAccessCode>({
    defaultValues: {
      code: '',
      count: 1
    }
  })

  const onSubmit = (data: ICreateAccessCode) => {
    mutate(data)
  }

  return (
    <ProtectedComponent permissionSlug={'access_codes_create'}>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogTrigger asChild>
          <Button variant={'create'}>Create Code</Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {type === 'individual'
                ? 'Create Access Code'
                : 'Create Access Codes'}
            </DialogTitle>
          </DialogHeader>
          <form className='flex w-full flex-col gap-5'>
            <Switch
              title={type === 'individual' ? 'Create one' : 'Create many'}
              checked={type === 'group'}
              onCheckedChange={() =>
                setType(type === 'individual' ? 'group' : 'individual')
              }
            />

            {type === 'individual' ? (
              <Field
                placeholder='Access Code'
                type='text'
                {...register('code', {
                  required: 'This field is required',
                  minLength: {
                    value: 5,
                    message: 'Access code must be at least 5 characters'
                  },
                  maxLength: {
                    value: 5,
                    message: 'Access code must not exceed 5 characters'
                  },
                  pattern: {
                    value: /^[a-zA-Z0-9]+$/,
                    message: 'Invalid access code'
                  }
                })}
                error={errors.code?.message}
              />
            ) : (
              <Field
                placeholder='Count'
                type='number'
                {...register('count', {
                  required: 'This field is required',
                  min: {
                    value: 1,
                    message: 'Count must be at least 1'
                  },
                  setValueAs: value => Number(value)
                })}
                error={errors.count?.message}
              />
            )}

            <Button
              disabled={isPending}
              onClick={handleSubmit(onSubmit)}
              size={'lg'}
            >
              {isPending ? 'Loading...' : 'Confirm'}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </ProtectedComponent>
  )
}
