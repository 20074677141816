import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import ProtectedComponent from '@/components/Protected'
import { Button } from '@/components/ui/Button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/Dialog'
import Field from '@/components/ui/Field'
import Switch from '@/components/ui/Switch'

import {
  IAccessCode,
  IHandleSentAccessCode
} from '@/types/access-code/access-code.interface'

import { errorCatch } from '@/api/error'

import api from '@/api'

interface Props {
  initial: IAccessCode
}

const ToggleIsSent: React.FC<Props> = ({ initial }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const queryClient = useQueryClient()

  const { mutate, isPending } = useMutation({
    mutationKey: ['toggle-is-sent', initial.id],
    mutationFn: async (data: IHandleSentAccessCode) => {
      await api.put(`admin/access-codes/${initial.id}/handle-sent`, {
        ...data
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries()
      toast.success('Access code updated')
      setIsOpen(false)
    },
    onError: error => {
      const errorMessage = errorCatch(error)
      toast.error(
        errorMessage || 'An error occurred while updating the access code'
      )
    }
  })

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<IHandleSentAccessCode>({
    defaultValues: {
      is_sent: initial.is_sent,
      sent_to: initial.sent_to
    }
  })

  useEffect(() => {
    reset({
      is_sent: initial.is_sent,
      sent_to: initial.sent_to
    })
  }, [initial, reset])

  const onSubmit = (data: IHandleSentAccessCode) => {
    mutate(data)
  }

  return (
    <ProtectedComponent permissionSlug={'access_codes_update'}>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogTrigger asChild>
          <Button variant={'edit'}>Edit</Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Update Access Code</DialogTitle>
          </DialogHeader>
          <form className='flex w-full flex-col gap-5'>
            <Controller
              control={control}
              name='is_sent'
              render={({ field }) => (
                <div className='flex flex-col gap-2'>
                  <Switch
                    title={
                      field.value
                        ? 'Access code has been sent'
                        : 'Access code has not been sent'
                    }
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />

                  {field.value && (
                    <Field
                      placeholder='Sent To'
                      type='text'
                      {...register('sent_to')}
                      error={errors.sent_to?.message}
                    />
                  )}
                </div>
              )}
            />

            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={isPending}
              size={'lg'}
            >
              {isPending ? 'Loading...' : 'Confirm'}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </ProtectedComponent>
  )
}

export default ToggleIsSent
