import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import ProtectedComponent from '@/components/Protected'
import { Button } from '@/components/ui/Button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/Dialog'
import Field, { ErrorMessage } from '@/components/ui/Field'

import { INotificationCreate } from '@/types/notifications/notification.interface'

import api from '@/api'
import NotificationsAutocomplete from '@/pages/Notifications/NotificationsAutocomplete'

export const SendNotification = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const queryClient = useQueryClient()

  const { mutate, isPending } = useMutation({
    mutationKey: ['sendNotification'],
    mutationFn: async (data: INotificationCreate) => {
      const resp = await api({
        url: 'admin/notifications/send',
        method: 'POST',
        data
      })

      return resp.data
    },
    onSuccess: async () => {
      reset()
      queryClient.refetchQueries()
      setIsOpen(false)
      toast.success('Notification successfully sent!', {
        id: 'send-notification'
      })
    },
    onError: error => {
      toast.error(error.message, {
        id: 'send-notification'
      })
    }
  })

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<INotificationCreate>({
    defaultValues: {
      title: '',
      body: '',
      receiver: undefined
    }
  })

  useEffect(() => {
    reset({
      title: '',
      body: '',
      receiver: undefined
    })
  }, [])

  const onSubmit = (data: INotificationCreate) => {
    mutate(data)
  }

  return (
    <ProtectedComponent permissionSlug={'users_send_notifications'}>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogTrigger asChild>
          <Button variant={'create'} className='ml-auto'>
            Send notification
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Create notification</DialogTitle>
          </DialogHeader>

          <form className='flex w-full flex-col gap-5'>
            <Field
              placeholder='Notification title'
              {...register('title', {
                required: 'Notification title is required'
              })}
              className='!mt-0'
              error={errors.title?.message}
            />
            <Field
              placeholder='Notification body'
              {...register('body', {
                required: 'Notification body is required'
              })}
              className='!mt-0'
              error={errors.body?.message}
            />
            <Controller
              name='receiver'
              control={control}
              rules={{ required: 'Receiver is required' }}
              render={({ field }) => {
                return (
                  <div className='flex flex-col'>
                    <NotificationsAutocomplete
                      selected={field.value}
                      setSelected={field.onChange}
                    />
                    <ErrorMessage error={errors?.receiver?.message} />
                  </div>
                )
              }}
            />
            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={isPending}
              size={'lg'}
            >
              {isPending ? 'Loading...' : 'Send notification'}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </ProtectedComponent>
  )
}
