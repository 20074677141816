import { ColumnDef } from '@tanstack/react-table'
import toast from 'react-hot-toast'

import UserDetailsModal from '@/components/features/UserDetails/Modal'
import { DataTableColumnHeader } from '@/components/ui/DataTable/ColumnHeader'

import { IUser } from '@/types/account/user.interface'

import formatId from '@/utils/formatId'

import { msatsToSats } from '@/utils'

export const columns: ColumnDef<IUser>[] = [
  {
    accessorKey: 'id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Id' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.id}`)
            toast.success('ID Copied to clipboard')
          }}
        >
          {formatId(row.original.id)}
        </button>
      )
    }
  },
  {
    accessorKey: 'account',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Account' />
    },
    cell: ({ row }) => {
      return <UserDetailsModal fromOtherPage user={row.original} />
    },
    enableSorting: false
  },
  {
    accessorKey: 'account.email',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Email' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(row.original.email)
            toast.success('Email copied to clipboard')
          }}
          className='text-foreground/50'
        >
          {row.original.email}
        </button>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'refer_claimable_msats',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Claimable' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-primary'>
          {msatsToSats(row.original.refer_claimable_msats).toString()} sats
        </p>
      )
    }
  },
  {
    accessorKey: 'referrals',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Referred' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>
          {row.original.referrals.length || 0} users
        </p>
      )
    }
  },
  {
    accessorKey: 'refer_earnings_msats',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Total Earned' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-green550'>
          {Number(msatsToSats(row.original.refer_earnings_msats)) +
            Number(msatsToSats(row.original.refer_claimable_msats))}{' '}
          sats
        </p>
      )
    }
  }
]
