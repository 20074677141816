import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import ProtectedComponent from '@/components/Protected'
import { Button } from '@/components/ui/Button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/Dialog'
import Field from '@/components/ui/Field'
import Switch from '@/components/ui/Switch'

import { ICreateOrUpdateCategory } from '@/types/learn/category.interface'

import { errorCatch } from '@/api/error'

import api from '@/api'

interface Props {
  initial?: ICreateOrUpdateCategory
  url: string
}

const CreateOrUpdateCategory: React.FC<Props> = ({ initial, url }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<ICreateOrUpdateCategory>({
    mode: 'onChange',
    defaultValues: initial || {
      name: '',
      status: 1
    }
  })

  useEffect(() => {
    reset(
      initial || {
        name: '',
        status: 1
      }
    )
  }, [initial, reset])

  const queryClient = useQueryClient()

  const { isPending, mutate } = useMutation({
    mutationKey: [initial ? 'update-category' : 'add-category'],
    mutationFn: (data: ICreateOrUpdateCategory) =>
      api(url, {
        method: initial ? 'PATCH' : 'POST',
        data: {
          ...data,
          status: Number(data.status)
        }
      }),
    onSuccess: () => {
      toast.success(
        `Category ${initial ? 'updated' : 'created'} successfully!`,
        {
          id: initial ? 'update-category' : 'add-category'
        }
      )
      reset()
      queryClient.invalidateQueries()
      setIsOpen(false)
    },
    onError: error => {
      const errorMessage = errorCatch(error)
      toast.error(
        errorMessage || `Failed to ${initial ? 'update' : 'create'} category`,
        {
          id: initial ? 'update-category' : 'add-category'
        }
      )
    }
  })

  const onSubmit: SubmitHandler<ICreateOrUpdateCategory> = async data =>
    mutate(data)

  return (
    <ProtectedComponent
      permissionSlug={initial ? 'learn_update' : 'learn_create'}
    >
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogTrigger asChild>
          <Button variant={initial ? 'edit' : 'create'}>
            {initial ? 'Edit' : 'Create'}
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {initial ? 'Edit Category' : 'Create Category'}
            </DialogTitle>
          </DialogHeader>
          <form className='mt-4 flex flex-col gap-5'>
            <Field
              placeholder='name'
              {...register('name', {
                required: 'Category Name is required',
                minLength: {
                  value: 3,
                  message: 'Category Name should be at least 3 characters long'
                }
              })}
              error={errors.name?.message}
            />
            <Controller
              name='status'
              control={control}
              render={({ field }) => (
                <Switch
                  title='Visibility'
                  checked={field.value === 1}
                  onCheckedChange={(checked: boolean) => {
                    field.onChange(checked ? 1 : 0)
                  }}
                />
              )}
            />
            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={isPending}
              size={'lg'}
            >
              {isPending ? 'Loading...' : 'Confirm'}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </ProtectedComponent>
  )
}

export default CreateOrUpdateCategory
