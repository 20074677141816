import { useMutation, useQueryClient } from '@tanstack/react-query'
import React, { useState } from 'react'
import toast from 'react-hot-toast'

import ProtectedComponent from '@/components/Protected'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@/components/ui/AlertDialog'

import { Button } from '../ui/Button'

import api from '@/api'

interface Props {
  url: string
  method?: 'POST' | 'PUT' | 'DELETE' | 'PATCH'
  body?: unknown
  onSuccess?: () => void
  buttonTitle?: string
  buttonClassName?: string
  title?: string
  description?: string
  successMessage?: string
  errorMessage?: string
  permissionSlug?: string
}

const MutationActionButton: React.FC<Props> = ({
  url,
  method = 'DELETE',
  body,
  onSuccess,
  buttonClassName,
  buttonTitle = 'Delete',
  title = 'Delete Item',
  description = 'Are you sure you want to delete this item?',
  successMessage = 'Item deleted successfully',
  errorMessage = 'An error occurred while deleting the item',
  permissionSlug
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const queryClient = useQueryClient()

  const { mutate, isPending } = useMutation({
    mutationKey: ['delete-item', url],
    mutationFn: async () => {
      await api.request({
        method,
        url,
        data: body
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries()
      toast.success(successMessage)
      if (onSuccess) {
        onSuccess()
      }
      setIsOpen(false)
    },
    onError: () => {
      toast.error(errorMessage)
    }
  })

  return (
    <ProtectedComponent permissionSlug={permissionSlug}>
      <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
        <AlertDialogTrigger asChild>
          <Button
            variant={'destructive'}
            disabled={isPending}
            className={buttonClassName}
          >
            {buttonTitle}
          </Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{title}</AlertDialogTitle>
            <AlertDialogDescription>{description}</AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={() => mutate()}>
              Confirm
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </ProtectedComponent>
  )
}

export default MutationActionButton
