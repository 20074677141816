import { BadgeDollarSign, CloudUpload } from 'lucide-react'

import Icon from '@/components/ui/Icon'

import { BANK_TABS, LEARN_TABS, REFER_TABS, USERS_TABS } from './tabs'

export type MenuItem = {
  label: string
  icon: JSX.Element
  url: string
  relatedUrl: string
  permissionSlug?: string
}

export const MENU_ITEMS: MenuItem[] = [
  {
    label: 'Users',
    icon: <Icon icon='User' className='size-6' />,
    url: USERS_TABS[0].url,
    relatedUrl: '/users'
  },
  {
    label: 'Metrics',
    icon: <Icon icon='Metrics' className='size-6' />,
    url: '/metrics',
    relatedUrl: '/metrics',
    permissionSlug: 'metrics_view'
  },
  {
    label: 'Earn',
    icon: <Icon icon='Earn' className='size-6' />,
    url: '/earn',
    relatedUrl: '/earn',
    permissionSlug: 'earn_view'
  },
  {
    label: 'Learn',
    icon: <Icon icon='Learn' className='size-6' />,
    url: LEARN_TABS[0].url,
    relatedUrl: '/learn',
    permissionSlug: 'learn_view'
  },
  {
    label: 'Bank',
    icon: <BadgeDollarSign className='size-6' />,
    url: BANK_TABS[0].url,
    relatedUrl: '/bank',
    permissionSlug: 'bank_view'
  },
  {
    label: 'Transfers',
    icon: <BadgeDollarSign className='size-6' />,
    url: '/transfers',
    relatedUrl: '/transfers',
    permissionSlug: 'transfers_view'
  },
  {
    label: 'Refer',
    icon: <Icon icon='Refer' className='size-6' />,
    url: REFER_TABS[0].url,
    relatedUrl: '/refer',
    permissionSlug: 'refer_view'
  },
  {
    label: 'Notifications',
    icon: <Icon icon='Notifications' className='size-6' />,
    url: '/notifications',
    relatedUrl: '/notifications'
  },
  {
    label: 'Deployments',
    icon: <CloudUpload className='size-6' />,
    url: '/deployments',
    relatedUrl: '/deployments'
  }
]
