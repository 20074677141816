import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import ProtectedComponent from '@/components/Protected'
import AccountSelect from '@/components/features/AccountSelect'
import { Button } from '@/components/ui/Button'
import { ErrorMessage } from '@/components/ui/Field'
import {
  Sheet,
  SheetContent,
  SheetTitle,
  SheetTrigger
} from '@/components/ui/Sheet'
import Switch from '@/components/ui/Switch'

import { ICreateEddRequest } from '@/types/bank-edd-request/bank-request.interface'

import { errorCatch } from '@/api/error'

import api from '@/api'

const CreateEDDRequest: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<ICreateEddRequest>({
    mode: 'onChange',
    defaultValues: {
      account_id: undefined,
      first_name_visible: false,
      last_name_visible: false,
      address_one_visible: false,
      address_two_visible: false,
      city_visible: false,
      zip_visible: false,
      id_number_type_visible: false,
      phone_number_visible: false,
      employment_status_visible: false,
      industry_visible: false,
      source_of_funds_visible: false,
      signed_agreements_visible: false,
      onboarded_location_visible: false,
      jurisdiction_code_visible: false,
      citizenship_code_visible: false
    }
  })

  const queryClient = useQueryClient()

  const { isPending, mutate } = useMutation({
    mutationKey: ['create-edd-request'],
    mutationFn: (data: ICreateEddRequest) =>
      api('/admin/bank/edd', {
        method: 'POST',
        data
      }),
    onSuccess: () => {
      toast.success(`EDD request created successfully!`)
      reset()
      queryClient.invalidateQueries()
      setIsOpen(false)
    },
    onError: error => {
      const errorMessage = errorCatch(error)
      toast.error(errorMessage || `Failed to 'create' EDD request`)
    }
  })

  const onSubmit: SubmitHandler<ICreateEddRequest> = async data => mutate(data)

  return (
    <ProtectedComponent permissionSlug='edd_requests_create'>
      <Sheet open={isOpen} onOpenChange={setIsOpen}>
        <SheetTrigger>
          <Button variant={'create'}>Create EDD Request</Button>
        </SheetTrigger>
        <SheetContent className='h-screen w-full border-solid border-gray500/50 bg-gray800 sm:max-w-lg '>
          <form className='flex h-[calc(100svh-100px)] w-full flex-col gap-2 overflow-y-auto p-4 md:gap-4 md:p-6'>
            <SheetTitle>Create EDD Request</SheetTitle>

            <Controller
              name='account_id'
              control={control}
              rules={{ required: 'Account is required' }}
              render={({ field }) => (
                <label className='flex flex-col gap-2'>
                  <p className='font-semibold'>Account</p>
                  <AccountSelect
                    accountId={field.value}
                    setAccountId={field.onChange}
                  />
                  {errors.account_id?.message && (
                    <ErrorMessage error={errors.account_id.message} />
                  )}
                </label>
              )}
            />
            <p className='font-semibold'>Details</p>
            <Controller
              name='first_name_visible'
              control={control}
              render={({ field }) => (
                <Switch
                  title='First Name'
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              )}
            />
            <Controller
              name='last_name_visible'
              control={control}
              render={({ field }) => (
                <Switch
                  title='Last Name'
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              )}
            />
            <Controller
              name='address_one_visible'
              control={control}
              render={({ field }) => (
                <Switch
                  title='Address One'
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              )}
            />
            <Controller
              name='address_two_visible'
              control={control}
              render={({ field }) => (
                <Switch
                  title='Address Two'
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              )}
            />
            <Controller
              name='city_visible'
              control={control}
              render={({ field }) => (
                <Switch
                  title='City'
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              )}
            />
            <Controller
              name='zip_visible'
              control={control}
              render={({ field }) => (
                <Switch
                  title='Zip'
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              )}
            />
            <Controller
              name='id_number_type_visible'
              control={control}
              render={({ field }) => (
                <Switch
                  title='ID Number Type'
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              )}
            />
            <Controller
              name='phone_number_visible'
              control={control}
              render={({ field }) => (
                <Switch
                  title='Phone Number'
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              )}
            />
            <Controller
              name='employment_status_visible'
              control={control}
              render={({ field }) => (
                <Switch
                  title='Employment Status'
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              )}
            />
            <Controller
              name='industry_visible'
              control={control}
              render={({ field }) => (
                <Switch
                  title='Industry'
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              )}
            />
            <Controller
              name='source_of_funds_visible'
              control={control}
              render={({ field }) => (
                <Switch
                  title='Source of Funds'
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              )}
            />
            <Controller
              name='onboarded_location_visible'
              control={control}
              render={({ field }) => (
                <Switch
                  title='Onboarded Location'
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              )}
            />
            <Controller
              name='jurisdiction_code_visible'
              control={control}
              render={({ field }) => (
                <Switch
                  title='Jurisdiction Code'
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              )}
            />
            <Controller
              name='citizenship_code_visible'
              control={control}
              render={({ field }) => (
                <Switch
                  title='Citizenship Code'
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              )}
            />

            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={isPending}
              size={'lg'}
            >
              {isPending ? 'Loading...' : 'Confirm'}
            </Button>
          </form>
        </SheetContent>
      </Sheet>
    </ProtectedComponent>
  )
}

export default CreateEDDRequest
