import { ColumnDef } from '@tanstack/react-table'
import { format } from 'date-fns'
import toast from 'react-hot-toast'

import GetUserCountry from '@/components/features/UserDetails/GetUserCountry'
import UserDetailsModal from '@/components/features/UserDetails/Modal'
import { DataTableColumnHeader } from '@/components/ui/DataTable/ColumnHeader'

import { IUser } from '@/types/account/user.interface'

import formatId from '@/utils/formatId'
import { returnKycIcon } from '@/utils/return-kyc-icon'

import { cn } from '@/utils'

export const columns: ColumnDef<IUser>[] = [
  {
    accessorKey: 'id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Id' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.id}`)
            toast.success('ID Copied to clipboard')
          }}
        >
          {formatId(row.original.id)}
        </button>
      )
    }
  },
  {
    accessorKey: 'display_name',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Display Name' />
    },
    cell: ({ row }) => {
      if (row.original.display_name) {
        return <p>{row.original.display_name}</p>
      } else {
        return <p className='text-foreground/50'>-</p>
      }
    }
  },
  {
    accessorKey: 'legal_name',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Legal Name' />
    },
    cell: ({ row }) => {
      if (row.original.first_name && row.original.last_name) {
        return (
          <p>
            {row.original.first_name} {row.original.last_name}
          </p>
        )
      } else {
        return <p className='min-w-28 text-foreground/50'>Not Verified</p>
      }
    },
    enableSorting: false
  },
  {
    accessorKey: 'username',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='@user' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`@${row.original.username}`)
            toast.success('Username Copied to clipboard')
          }}
          className='text-foreground/50'
        >
          @{row.original.username}
        </button>
      )
    }
  },
  {
    accessorKey: 'email',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Email' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(row.original.email)
            toast.success('Email copied to clipboard')
          }}
          className='text-foreground/50'
        >
          {row.original.email}
        </button>
      )
    }
  },
  {
    accessorKey: 'avatar',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Avatar' />
    },
    cell: ({ row }) => {
      return row.original.avatar ? (
        <div
          className='h-[30px] w-[30px] overflow-hidden rounded-full bg-cover bg-center'
          style={{
            backgroundImage: `url(${row.original.avatar})`
          }}
        />
      ) : (
        <div className='h-[30px] w-[30px] rounded-full bg-white/20' />
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'created_at',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Date Joined' />
    },
    cell: ({ row }) => {
      const date = new Date(row.original.created_at)
      if (date.toString() === 'Invalid Date') {
        return <p className='text-foreground/50'>-</p>
      }

      return <p className='text-foreground/50'>{format(date, 'yyyy.MM.dd')}</p>
    }
  },
  {
    accessorKey: 'ip_addresses',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Country' />
    },
    cell: ({ row }) => {
      if (!row.original.ip_addresses || !row.original.ip_addresses.length) {
        return <p className='text-foreground/50'>Uknown</p>
      }

      return <GetUserCountry user={row.original} />
    },
    enableSorting: false
  },
  {
    accessorKey: 'kyc_status',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='KYC' />
    },
    cell: ({ row }) => {
      return <>{returnKycIcon(row.original.kyc_status)}</>
    },
    enableSorting: false
  },
  {
    accessorKey: 'membership_type',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Satoshi+' />
    },
    cell: ({ row }) => {
      return (
        <p
          className={cn(
            'text-[14px] font-semibold tracking-normal text-white',
            row.original.membership_type === 'REGULAR'
              ? 'text-foreground/50'
              : ''
          )}
        >
          {row.original.membership_type === 'REGULAR'
            ? 'Regular'
            : 'Satoshi Plus'}
        </p>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'actions',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='' />
    },
    cell: ({ row }) => {
      return (
        <div className='flex w-fit flex-row gap-1 border-l-2 border-border pl-4'>
          <UserDetailsModal user={row.original} />
        </div>
      )
    },
    enableSorting: false
  }
]
