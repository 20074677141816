import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import ProtectedComponent from '@/components/Protected'
import { Button } from '@/components/ui/Button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/Dialog'
import TextArea from '@/components/ui/TextArea'

import { errorCatch } from '@/api/error'

import api from '@/api'

interface ICreateEmailWhitelist {
  emailsText: string
}

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

const CreateEmailWhitelist: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<ICreateEmailWhitelist>({
    mode: 'onChange',
    defaultValues: {
      emailsText: ''
    }
  })

  const queryClient = useQueryClient()

  const { isPending, mutate } = useMutation({
    mutationKey: ['add-many-email-whitelist'],
    mutationFn: (data: { emails: string[] }) =>
      api('/admin/email-whitelist/many', {
        method: 'POST',
        data
      }),
    onSuccess: () => {
      toast.success('Emails added to whitelist successfully!')
      reset()
      queryClient.invalidateQueries()
      setIsOpen(false)
    },
    onError: error => {
      const errorMessage = errorCatch(error)
      toast.error(errorMessage || 'Failed to add emails to whitelist')
    }
  })

  const onSubmit: SubmitHandler<ICreateEmailWhitelist> = data => {
    const emailsSet = new Set<string>()
    const invalidEmails: string[] = []

    const lines = data.emailsText.split('\n')
    lines.forEach(line => {
      const trimmedLine = line.trim()
      if (!trimmedLine) return

      if (emailRegex.test(trimmedLine)) {
        emailsSet.add(trimmedLine)
      } else {
        invalidEmails.push(trimmedLine)
      }
    })

    const emails = Array.from(emailsSet)

    if (emails.length === 0) {
      toast.error('No valid emails found')
      return
    }

    if (invalidEmails.length > 0) {
      toast.error(
        `Invalid emails detected: ${invalidEmails.join(',\n')}. They will be skipped.`,
        {
          duration: 100000
        }
      )
    }

    mutate({ emails })
  }

  return (
    <ProtectedComponent permissionSlug={'email_whitelist_update'}>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogTrigger asChild>
          <Button variant={'create'}>Add Emails</Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Whitelist Emails</DialogTitle>
          </DialogHeader>
          <form className='mt-4 flex flex-col gap-5'>
            <TextArea
              placeholder='Paste emails here (e.g., user1@example.com)'
              rows={10}
              {...register('emailsText', {
                required: 'Emails text is required'
              })}
              error={errors.emailsText?.message}
            />

            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={isPending}
              size={'lg'}
            >
              {isPending ? 'Loading...' : 'Confirm'}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </ProtectedComponent>
  )
}

export default CreateEmailWhitelist
