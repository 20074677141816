import {
  IAccountMetrics,
  IExchangeMetrics,
  IFeesMetrics,
  IMetricCard,
  IWalletMetrics
} from '@/types/metrics/metrics.interface'

import {
  formatBtc,
  formatInteger,
  formatNumber,
  msatsToSats,
  mstasToBtcAndFormat
} from '@/utils'

export const formatAccountMetrics = (data: IAccountMetrics) => {
  return [
    {
      title: 'Total Users',
      value: formatInteger(data.total)
    },
    {
      title: 'Total Referred',
      value: formatInteger(data.total_referred)
    },
    {
      title: 'Total Downloads',
      value: formatInteger(data.total_apple_downloads),
      subtitle: 'App Store'
    },
    {
      title: 'Total Downloads',
      value: formatInteger(data.total_google_downloads),
      subtitle: 'Google Play'
    },
    {
      title: 'Total Downloads',
      value: formatInteger(data.total_apk_downloads),
      subtitle: 'APK'
    },
    {
      title: 'Total Waitlisted',
      value: formatInteger(data.total_waitlisted)
    },
    {
      title: 'Total Satoshi Plus',
      value: formatInteger(data.total_satoshi_plus)
    },
    {
      title: 'Created Users',
      tabs: [
        {
          name: 'Day',
          value: formatInteger(data.created_24h)
        },
        {
          name: 'Week',
          value: formatInteger(data.created_week)
        },
        {
          name: 'Month',
          value: formatInteger(data.created_month)
        },
        {
          name: 'All',
          value: formatInteger(data.created_all)
        }
      ]
    },
    {
      title: 'Active Users',
      tabs: [
        {
          name: 'Day',
          value: formatInteger(data.active_24h)
        },
        {
          name: 'Week',
          value: formatInteger(data.active_week)
        },
        {
          name: 'Month',
          value: formatInteger(data.active_month)
        },
        {
          name: 'All',
          value: formatInteger(data.active_all)
        }
      ],
      className: 'xl:col-span-2'
    },
    {
      title: 'App Opens',
      tabs: [
        {
          name: 'Day',
          value: formatInteger(data.app_opens_24h)
        },
        {
          name: 'Week',
          value: formatInteger(data.app_opens_week)
        },
        {
          name: 'Month',
          value: formatInteger(data.app_opens_month)
        },
        {
          name: 'All',
          value: formatInteger(data.app_opens_all)
        }
      ],
      className: 'xl:col-span-2'
    }
  ] as IMetricCard[]
}

export const formatExchangeMetrics = (data: IExchangeMetrics) => {
  return [
    {
      title: 'BTC Under Custody',
      value: `${formatBtc(data.custodied_btc)} BTC`,
      titleColor: 'orange'
    },
    {
      title: 'USD Under Custody',
      value: `$${formatNumber(data.custodied_usd)}`,
      titleColor: 'blue'
    },
    {
      title: 'Buy Volume',
      tabs: [
        {
          name: 'Day',
          value: `$${formatNumber(data.trades_buy_usd_24h)}`
        },
        {
          name: 'Week',
          value: `$${formatNumber(data.trades_buy_usd_week)}`
        },
        {
          name: 'Month',
          value: `$${formatNumber(data.trades_buy_usd_month)}`
        },
        {
          name: 'All',
          value: `$${formatNumber(data.trades_buy_usd_all)}`
        }
      ],
      titleColor: 'green'
    },
    {
      title: 'Sell Volume',
      tabs: [
        {
          name: 'Day',
          value: `$${formatNumber(data.trades_sell_usd_24h)}`
        },
        {
          name: 'Week',
          value: `$${formatNumber(data.trades_sell_usd_week)}`
        },
        {
          name: 'Month',
          value: `$${formatNumber(data.trades_sell_usd_month)}`
        },
        {
          name: 'All',
          value: `$${formatNumber(data.trades_sell_usd_all)}`
        }
      ],
      titleColor: 'purple'
    },
    {
      title: 'Withdrawals Volume',
      tabs: [
        {
          name: 'Day',
          value: `$${formatNumber(data.withdrawal_usd_24h)}`
        },
        {
          name: 'Week',
          value: `$${formatNumber(data.withdrawal_usd_week)}`,
          limit: `$${formatNumber(data.limits.funding_withdrawal_weekly_limit)}`,
          progress:
            data.withdrawal_usd_week /
            Number(data.limits.funding_withdrawal_weekly_limit)
        },
        {
          name: 'Month',
          value: `$${formatNumber(data.withdrawal_usd_month)}`,
          limit: `$${formatNumber(data.limits.funding_withdrawal_monthly_limit)}`,
          progress:
            data.withdrawal_usd_month /
            Number(data.limits.funding_withdrawal_monthly_limit)
        },
        {
          name: 'All',
          value: `$${formatNumber(data.withdrawal_usd_all)}`
        }
      ],
      className: 'xl:col-span-2',
      titleColor: 'blue'
    },
    {
      title: 'Deposits Volume',
      tabs: [
        {
          name: 'Day',
          value: `$${formatNumber(data.deposit_usd_24h)}`
        },
        {
          name: 'Week',
          value: `$${formatNumber(data.deposit_usd_week)}`,
          limit: `$${formatNumber(data.limits.funding_deposit_weekly_limit)}`,
          progress:
            data.deposit_usd_week /
            Number(data.limits.funding_deposit_weekly_limit)
        },
        {
          name: 'Month',
          value: `$${formatNumber(data.deposit_usd_month)}`,
          limit: `$${formatNumber(data.limits.funding_deposit_monthly_limit)}`,
          progress:
            data.deposit_usd_month /
            Number(data.limits.funding_deposit_monthly_limit)
        },
        {
          name: 'All',
          value: `$${formatNumber(data.deposit_usd_all)}`
        }
      ],
      className: 'xl:col-span-2',
      titleColor: 'blue'
    }
  ] as IMetricCard[]
}

export const formatWalletMetrics = (data: IWalletMetrics) => {
  return [
    {
      title: 'Total Balance',
      value: `${mstasToBtcAndFormat(data.total_balance_msats)} BTC`,
      subtitle: `$${formatNumber(data.total_balance_usd)}`,
      className: 'md:col-span-2',
      titleColor: 'purple'
    },
    {
      title: 'Volume Sent',
      tabs: [
        {
          name: 'Day',
          value: `$${formatNumber(data.volume_sent_usd_24h)}`
        },
        {
          name: 'Week',
          value: `$${formatNumber(data.volume_sent_usd_week)}`
        },
        {
          name: 'Month',
          value: `$${formatNumber(data.volume_sent_usd_month)}`
        },
        {
          name: 'All',
          value: `$${formatNumber(data.volume_sent_usd_all)}`
        }
      ],
      className: 'md:col-span-2',
      titleColor: 'green'
    },
    {
      title: 'Volume Received',
      tabs: [
        {
          name: 'Day',
          value: `$${formatNumber(data.volume_received_usd_24h)}`
        },
        {
          name: 'Week',
          value: `$${formatNumber(data.volume_received_usd_week)}`
        },
        {
          name: 'Month',
          value: `$${formatNumber(data.volume_received_usd_month)}`
        },
        {
          name: 'All',
          value: `$${formatNumber(data.volume_received_usd_all)}`
        }
      ],
      className: 'md:col-span-2',
      titleColor: 'orange'
    },
    {
      title: 'Payments Sent',
      tabs: [
        {
          name: 'Day',
          value: formatInteger(data.payments_sent_24h)
        },
        {
          name: 'Week',
          value: formatInteger(data.payments_sent_week)
        },
        {
          name: 'Month',
          value: formatInteger(data.payments_sent_month)
        },
        {
          name: 'All',
          value: formatInteger(data.payments_sent_all)
        }
      ],
      className: 'xl:col-span-3',
      titleColor: 'blue'
    },
    {
      title: 'Payments Received',
      tabs: [
        {
          name: 'Day',
          value: formatInteger(data.payments_received_24h)
        },
        {
          name: 'Week',
          value: formatInteger(data.payments_received_week)
        },
        {
          name: 'Month',
          value: formatInteger(data.payments_received_month)
        },
        {
          name: 'All',
          value: formatInteger(data.payments_received_all)
        }
      ],
      className: 'xl:col-span-3',
      titleColor: 'blue'
    }
  ] as IMetricCard[]
}

export const formatFeesMetrics = (data: IFeesMetrics) => {
  return [
    {
      title: 'Exchange Buy',
      tabs: [
        {
          name: 'Day',
          value: `$${formatNumber(data.exchange_buy_24h)}`
        },
        {
          name: 'Week',
          value: `$${formatNumber(data.exchange_buy_week)}`
        },
        {
          name: 'Month',
          value: `$${formatNumber(data.exchange_buy_month)}`
        },
        {
          name: 'All',
          value: `$${formatNumber(data.exchange_buy_all)}`
        }
      ]
    },
    {
      title: 'Exchange Sell',
      tabs: [
        {
          name: 'Day',
          value: `$${formatNumber(data.exchange_sell_24h)}`
        },
        {
          name: 'Week',
          value: `$${formatNumber(data.exchange_sell_week)}`
        },
        {
          name: 'Month',
          value: `$${formatNumber(data.exchange_sell_month)}`
        },
        {
          name: 'All',
          value: `$${formatNumber(data.exchange_sell_all)}`
        }
      ]
    },
    {
      title: 'Exchange Send P2P',
      tabs: [
        {
          name: 'Day',
          value: `${msatsToSats(data.exchange_send_p2p_24h)} sats`
        },
        {
          name: 'Week',
          value: `${msatsToSats(data.exchange_send_p2p_week)} sats`
        },
        {
          name: 'Month',
          value: `${msatsToSats(data.exchange_send_p2p_month)} sats`
        },
        {
          name: 'All',
          value: `${msatsToSats(data.exchange_send_p2p_all)} sats`
        }
      ]
    },
    {
      title: 'Exchange Send Lightning',
      tabs: [
        {
          name: 'Day',
          value: `${formatInteger(Number(msatsToSats(data.exchange_send_lightning_24h)))} sats`
        },
        {
          name: 'Week',
          value: `${formatInteger(Number(msatsToSats(data.exchange_send_lightning_week)))} sats`
        },
        {
          name: 'Month',
          value: `${formatInteger(Number(msatsToSats(data.exchange_send_lightning_month)))} sats`
        },
        {
          name: 'All',
          value: `${formatInteger(Number(msatsToSats(data.exchange_send_lightning_all)))} sats`
        }
      ]
    },
    {
      title: 'Exchange Withdrawal',
      tabs: [
        {
          name: 'Day',
          value: `${formatInteger(Number(msatsToSats(data.exchange_onchain_withdraw_24h)))} sats`
        },
        {
          name: 'Week',
          value: `${formatInteger(Number(msatsToSats(data.exchange_onchain_withdraw_week)))} sats`
        },
        {
          name: 'Month',
          value: `${formatInteger(Number(msatsToSats(data.exchange_onchain_withdraw_month)))} sats`
        },
        {
          name: 'All',
          value: `${formatInteger(Number(msatsToSats(data.exchange_onchain_withdraw_all)))} sats`
        }
      ]
    },
    {
      title: 'Starter Send Lightning',
      tabs: [
        {
          name: 'Day',
          value: `${formatInteger(data.starter_send_lightning_24h)} sats`
        },
        {
          name: 'Week',
          value: `${formatInteger(data.starter_send_lightning_week)} sats`
        },
        {
          name: 'Month',
          value: `${formatInteger(data.starter_send_lightning_month)} sats`
        },
        {
          name: 'All',
          value: `${formatInteger(data.starter_send_lightning_all)} sats`
        }
      ]
    }
  ] as IMetricCard[]
}
