import { useEffect, useState } from 'react'

import { IUser } from '@/types/account/user.interface'

import { cn } from '@/utils'

interface Props {
  user: IUser
}

const GetUserCountry: React.FC<Props> = ({ user }) => {
  const [country, setCountry] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const ip = user.ip_addresses?.[0]?.value

  useEffect(() => {
    let isMounted = true
    if (!ip) {
      setCountry(null)
      setLoading(false)
      return
    }

    fetch(`https://ipwho.is/${ip}`)
      .then(res => res.json())
      .then(data => {
        if (isMounted) {
          if (data.success && data.country) {
            setCountry(data.country)
          } else {
            setCountry('Unknown')
          }
          setLoading(false)
        }
      })
      .catch(() => {
        if (isMounted) {
          setCountry('Unknown')
          setLoading(false)
        }
      })

    return () => {
      isMounted = false
    }
  }, [ip])

  if (loading) {
    return <p className='text-foreground/50'>Loading...</p>
  }

  return (
    <p
      className={cn('text-foreground', {
        'text-foreground/50': !country || country === 'Unknown'
      })}
    >
      {country || 'Unknown'}
    </p>
  )
}

export default GetUserCountry
