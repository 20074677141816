import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import ProtectedComponent from '@/components/Protected'
import { Button } from '@/components/ui/Button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/Dialog'
import Field, { ErrorMessage } from '@/components/ui/Field'

import { ICreateOrUpdateAPKFile } from '@/types/deployment/deployment.interface'

import { errorCatch } from '@/api/error'

import api from '@/api'

interface Props {
  deploymentId?: string
}

const CreateApk: React.FC<Props> = ({ deploymentId }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset
  } = useForm<ICreateOrUpdateAPKFile>({
    mode: 'onChange',
    defaultValues: {
      version: '',
      description: '',
      file: null
    }
  })

  useEffect(() => {
    if (!isOpen) {
      reset({
        version: '',
        description: '',
        file: null
      })
    }
  }, [isOpen, reset])

  const queryClient = useQueryClient()

  const { isPending, mutate } = useMutation({
    mutationKey: ['upload-apk', deploymentId],
    mutationFn: async (data: ICreateOrUpdateAPKFile) => {
      const formData = new FormData()
      formData.append('version', data.version)
      if (data.description) {
        formData.append('description', data.description)
      }
      if (data.file) {
        formData.append('file', data.file)
      }

      const endpoint = deploymentId
        ? `/admin/deployment/${deploymentId}/apk`
        : '/admin/deployment/apk'

      return api(endpoint, {
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        timeout: 0
      })
    },
    onSuccess: () => {
      toast.success('File uploaded successfully!')
      queryClient.invalidateQueries()
      setIsOpen(false)
    },
    onError: error => {
      const errorMessage = errorCatch(error)
      toast.error(errorMessage || 'An error occurred while uploading the file')
    }
  })

  const onSubmit: SubmitHandler<ICreateOrUpdateAPKFile> = async data =>
    mutate(data)

  return (
    <ProtectedComponent permissionSlug='deployments_create'>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogTrigger asChild>
          <Button variant={'create'}>
            {deploymentId ? 'Add APK' : 'Create APK'}
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {deploymentId
                ? 'Add APK (or other file type)'
                : 'Create APK (or other file type)'}
            </DialogTitle>
          </DialogHeader>
          <form className='flex w-full flex-col gap-5'>
            <Field
              title='Version'
              placeholder='Version'
              {...register('version', {
                required: 'Version is required',
                maxLength: {
                  value: 50,
                  message: 'Version should be less than 50 characters'
                },
                minLength: {
                  value: 3,
                  message: 'Version should be at least 3 characters'
                },
                pattern: {
                  value: /^\d+\.\d+\.\d+$/,
                  message: 'Version should be in the format of 1.1.1'
                }
              })}
              error={errors.version?.message}
            />

            <Controller
              control={control}
              name='file'
              rules={{
                required: 'A file is required'
              }}
              render={({ field }) => (
                <div className='flex flex-col'>
                  <label className='mb-2 text-lg font-bold text-white'>
                    Android Installation File (.apk, .aab, etc.)
                  </label>
                  <input
                    type='file'
                    onChange={e => field.onChange(e.target.files?.[0] ?? null)}
                    className='w-full border-2 p-2 dark:border-gray500/20 dark:bg-gray800'
                  />
                  <ErrorMessage error={errors.file?.message} />
                </div>
              )}
            />

            <Field
              title='Description (optional)'
              placeholder='Description'
              className='!mt-0'
              {...register('description', {
                maxLength: {
                  value: 255,
                  message: 'Description should be less than 255 characters'
                },
                minLength: {
                  value: 3,
                  message: 'Description should be at least 3 characters'
                }
              })}
              error={errors.description?.message}
            />

            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={isPending}
              size={'lg'}
            >
              {isPending ? 'Uploading...' : 'Upload'}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </ProtectedComponent>
  )
}

export default CreateApk
