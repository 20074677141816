import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import ProtectedComponent from '@/components/Protected'
import { Button } from '@/components/ui/Button'

import { IUser } from '@/types/account/user.interface'

import { errorCatch } from '@/api/error'

import { Section, SectionOption } from './Section'
import api from '@/api'

interface Props {
  noTitle?: boolean
  user: IUser
}

const UserActions: React.FC<Props> = ({ user, noTitle = false }) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { mutate, isPending } = useMutation({
    mutationKey: ['update-user', user.id],
    mutationFn: async (params: {
      url: string
      method: string
      errorTitle: string
      successMessage: string
    }) => {
      const resp = await api(params.url, {
        method: params.method
      })

      return resp.data
    },
    onError(error, context) {
      const errorMessage = errorCatch(error)
      toast.error(errorMessage || context.errorTitle)
    },
    onSuccess(data, context) {
      queryClient.invalidateQueries()

      switch (context.successMessage) {
        case 'DELETED':
          toast.success('User successfully hard deleted.')
          navigate('/')
          break
        case 'OTP':
          const toastId = toast.success(
            <div className='flex flex-col gap-2'>
              <p>
                OTP sent to @{user?.username} successfully.
                <br />
                OTP: <b className='text-lg font-bold'>{data}</b>
                <br />
                Click close to copy the OTP.
              </p>
              <Button
                variant={'destructive'}
                onClick={() => {
                  navigator.clipboard.writeText(data)
                  toast.dismiss(toastId)
                }}
                className='self-end'
              >
                Close
              </Button>
            </div>,
            {
              position: 'top-center',
              duration: 100000
            }
          )
          break
        default:
          toast.success(context.successMessage)
          break
      }
    }
  })

  const handleBlock = async () => {
    mutate({
      url: `/admin/users/${user.id}/handle-block`,
      method: 'PUT',
      errorTitle: user?.blocked
        ? 'An error occurred while unblocking the user.'
        : 'An error occurred while blocking the user.',
      successMessage: user?.blocked ? 'User unblocked.' : 'User blocked.'
    })
  }

  const isAllIpsBlocked = user?.ip_addresses?.every(ip => ip.is_blocked)

  const handleBlockIps = async () => {
    mutate({
      url: `/admin/users/${user.id}/handle-block-ip`,
      method: 'PUT',
      errorTitle: isAllIpsBlocked
        ? 'An error occurred while unblocking the user IP addresses.'
        : 'An error occurred while blocking the user IP addresses.',
      successMessage: user?.blocked
        ? 'User IP addresses unblocked.'
        : 'User IP addresses blocked.'
    })
  }

  const handleSoftDelete = async () => {
    mutate({
      url: `/admin/users/${user.id}/handle-delete`,
      method: 'PUT',
      errorTitle: user?.is_deleted
        ? 'An error occurred while restoring the user.'
        : 'An error occurred while soft deleting the user.',
      successMessage: user?.is_deleted ? 'User restored.' : 'User soft deleted.'
    })
  }

  const handleHardDelete = async () => {
    mutate({
      url: `/admin/users/${user.id}`,
      method: 'DELETE',
      errorTitle: 'An error occurred while hard deleting the user.',
      successMessage: 'DELETED'
    })
  }

  const handleWithdrawal = async () => {
    mutate({
      url: `admin/users/${user.id}/handle-withdrawal`,
      method: 'PUT',
      errorTitle: user?.withdrawals_paused
        ? 'An error occurred while resuming withdrawals.'
        : 'An error occurred while pausing withdrawals.',
      successMessage: user?.withdrawals_paused
        ? 'Withdrawals resumed.'
        : 'Withdrawals paused.'
    })
  }

  const handleResetTwoFactor = async () => {
    mutate({
      url: `admin/users/${user.id}/reset-2fa`,
      method: 'PUT',
      errorTitle: 'An error occurred while resetting the 2FA.',
      successMessage: '2FA reset.'
    })
  }

  const handleSendOtp = async () => {
    mutate({
      url: `admin/users/${user.id}/send-otp`,
      method: 'PUT',
      errorTitle: 'An error occurred while sending the OTP.',
      successMessage: 'OTP'
    })
  }

  return (
    <Section
      title={noTitle ? undefined : 'Actions'}
      loading={isPending}
      options={
        [
          user?.avatar && {
            label: 'Remove Avatar',
            rightContent: (
              <ProtectedComponent permissionSlug='users_update'>
                <Button
                  variant={'destructive'}
                  onClick={() =>
                    mutate({
                      url: `/admin/users/${user.id}/update-avatar`,
                      method: 'PATCH',
                      errorTitle:
                        'An error occurred while removing the avatar.',
                      successMessage: 'Avatar removed.'
                    })
                  }
                >
                  Remove
                </Button>
              </ProtectedComponent>
            )
          },
          user?.blocked
            ? {
                label: 'Unblock Account',
                rightContent: (
                  <ProtectedComponent permissionSlug='users_handle_block'>
                    <Button variant={'destructive'} onClick={handleBlock}>
                      Unblock
                    </Button>
                  </ProtectedComponent>
                )
              }
            : {
                label: 'Block Account',
                rightContent: (
                  <ProtectedComponent permissionSlug='users_handle_block'>
                    <Button variant={'destructive'} onClick={handleBlock}>
                      Block
                    </Button>
                  </ProtectedComponent>
                )
              },
          user.ip_addresses && user.ip_addresses.length > 0
            ? isAllIpsBlocked
              ? {
                  label: "Unblock IP's",
                  rightContent: (
                    <ProtectedComponent permissionSlug='users_handle_block'>
                      <Button variant={'destructive'} onClick={handleBlockIps}>
                        Unblock
                      </Button>
                    </ProtectedComponent>
                  )
                }
              : {
                  label: "Block IP's",
                  rightContent: (
                    <ProtectedComponent permissionSlug='users_handle_block'>
                      <Button variant={'destructive'} onClick={handleBlockIps}>
                        Block
                      </Button>
                    </ProtectedComponent>
                  )
                }
            : null,
          user?.is_two_factor_enabled && {
            label: 'Reset 2FA',
            rightContent: (
              <ProtectedComponent permissionSlug='users_handle_block'>
                <Button variant={'destructive'} onClick={handleResetTwoFactor}>
                  Reset
                </Button>
              </ProtectedComponent>
            )
          },
          {
            label: 'Send OTP',
            rightContent: (
              <ProtectedComponent permissionSlug='users_handle_block'>
                <Button variant={'destructive'} onClick={handleSendOtp}>
                  Send
                </Button>
              </ProtectedComponent>
            )
          },
          user?.withdrawals_paused
            ? {
                label: 'Resume Withdraw',
                rightContent: (
                  <ProtectedComponent permissionSlug='users_handle_withdraw'>
                    <Button variant={'destructive'} onClick={handleWithdrawal}>
                      Resume
                    </Button>
                  </ProtectedComponent>
                )
              }
            : {
                label: 'Pause Withdraw',
                rightContent: (
                  <ProtectedComponent permissionSlug='users_handle_withdraw'>
                    <Button variant={'destructive'} onClick={handleWithdrawal}>
                      Pause
                    </Button>
                  </ProtectedComponent>
                )
              },
          user?.is_deleted
            ? {
                label: 'Restore Account',
                rightContent: (
                  <ProtectedComponent permissionSlug='users_soft_delete'>
                    <Button variant={'destructive'} onClick={handleSoftDelete}>
                      Restore
                    </Button>
                  </ProtectedComponent>
                )
              }
            : {
                label: 'Delete (soft)',
                rightContent: (
                  <ProtectedComponent permissionSlug='users_soft_delete'>
                    <Button variant={'destructive'} onClick={handleSoftDelete}>
                      Delete
                    </Button>
                  </ProtectedComponent>
                )
              },
          {
            label: 'Delete (hard)',
            rightContent: (
              <ProtectedComponent permissionSlug='users_hard_delete'>
                <Button variant={'destructive'} onClick={handleHardDelete}>
                  Delete
                </Button>
              </ProtectedComponent>
            )
          }
        ].filter(Boolean) as SectionOption[]
      }
    />
  )
}

export default UserActions
