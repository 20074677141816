import { LoaderIcon } from 'lucide-react'
import { useEffect, useState } from 'react'

import { IMetricCard, IMetricCardTab } from '@/types/metrics/metrics.interface'

import ProgressBar from '../ProgressBar'

import { cn } from '@/utils'

interface Props {
  cardsConfig: {
    title?: string
    subtitle?: string
    count: number
  }
  isLoading: boolean
  isError: boolean
  data?: IMetricCard[]
}

const MetricsCards: React.FC<Props> = ({
  isLoading,
  data,
  cardsConfig,
  isError
}) => {
  return (
    <section className='flex flex-col gap-2'>
      {cardsConfig.title || cardsConfig.subtitle ? (
        <div className='flex flex-row items-end gap-2'>
          {cardsConfig.title && (
            <h2 className='text-xl font-semibold text-foreground xl:text-2xl'>
              {cardsConfig.title}
            </h2>
          )}
          {cardsConfig.subtitle && (
            <p className='text-lg font-medium text-foreground/50'>
              {cardsConfig.subtitle}
            </p>
          )}
        </div>
      ) : null}
      <section
        className={cn('grid grid-cols-1 gap-2 md:gap-3 xl:gap-4', {
          'md:grid-cols-2': cardsConfig.count === 2,
          'md:grid-cols-2 xl:grid-cols-3': cardsConfig.count === 3,
          'md:grid-cols-2 xl:grid-cols-4': cardsConfig.count === 4,
          'md:grid-cols-3 xl:grid-cols-5': cardsConfig.count === 5,
          'md:grid-cols-6': cardsConfig.count === 6
        })}
      >
        {isLoading ? (
          Array(cardsConfig.count)
            .fill(null)
            .map((_, index) => (
              <MetricCardSkeleton key={index} isError={isError} />
            ))
        ) : isError || !data ? (
          <MetricCardSkeleton isError={true} />
        ) : (
          data?.map((card, index) => <MetricCard key={index} {...card} />)
        )}
      </section>
    </section>
  )
}

export default MetricsCards

const MetricCard: React.FC<IMetricCard> = ({
  tabs,
  title,
  subtitle,
  value,
  limit,
  progress,
  titleColor,
  className
}) => {
  const isTabbed = tabs && tabs?.length > 0
  const [selectedTab, setSelectedTab] = useState<IMetricCardTab | undefined>(
    isTabbed ? tabs.find(tab => tab.name === 'All') || tabs[0] : undefined
  )

  useEffect(() => {
    if (isTabbed) {
      setSelectedTab(
        tabs.find(tab => tab.name === 'All') || tabs[0] || undefined
      )
    } else {
      setSelectedTab(undefined)
    }
  }, [tabs, isTabbed])

  return (
    <article
      className={cn(
        'flex h-52 w-full flex-col items-center justify-center gap-2 rounded-5xl border border-gray700 bg-background bg-gray800 p-4',
        className
      )}
    >
      <h3
        className={cn('text-xl font-bold xl:text-2xl 2xl:text-3xl', {
          'text-orange500': titleColor === 'orange',
          'text-blue500': titleColor === 'blue',
          'text-lime500': titleColor === 'green',
          'text-purple400': titleColor === 'purple'
        })}
      >
        {isTabbed ? `${selectedTab?.value}` : value}{' '}
        {isTabbed
          ? selectedTab?.limit && ` / ${selectedTab?.limit}`
          : limit && ` / ${limit}`}
      </h3>
      {(isTabbed && selectedTab?.limit) || limit ? (
        <div className='w-full max-w-60'>
          <ProgressBar
            value={isTabbed ? selectedTab?.progress || 0 : progress || 0}
            color={titleColor}
          />
        </div>
      ) : null}
      <p className='text-gray400 text-xl font-medium text-foreground/80 md:text-xl'>
        {title}
      </p>
      {isTabbed && tabs ? (
        <section className='mt-2 flex flex-row gap-1 rounded-full bg-gray900 p-1'>
          {tabs.map((tab, index) => (
            <button
              key={index}
              className={cn(
                'duration-250 cursor-pointer rounded-full bg-transparent px-3 py-2 text-xs font-semibold text-foreground transition-all',
                selectedTab?.name === tab.name && 'bg-gray700'
              )}
              onClick={() => setSelectedTab(tab)}
            >
              {tab.name}
            </button>
          ))}
        </section>
      ) : (
        <p className='text-lg font-semibold text-foreground xl:text-xl'>
          {subtitle}
        </p>
      )}
    </article>
  )
}

const MetricCardSkeleton: React.FC<{ isError: boolean }> = ({ isError }) => {
  return (
    <article className='flex h-52 w-full flex-col items-center justify-center gap-2 rounded-5xl border border-gray700 bg-background bg-gray800 p-4'>
      {isError ? (
        <p className='text-xl font-semibold text-foreground/80'>
          Failed <br />
          to fetch metrics
        </p>
      ) : (
        <LoaderIcon className='size-8 animate-spin text-primary' />
      )}
    </article>
  )
}
