import { ColumnDef } from '@tanstack/react-table'
import { format } from 'date-fns'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'

import UserCard from '@/components/features/UserCard'
import UserDetailsModal from '@/components/features/UserDetails/Modal'
import { DataTableColumnHeader } from '@/components/ui/DataTable/ColumnHeader'

import { INotification } from '@/types/notifications/notification.interface'

import formatId from '@/utils/formatId'

export const columns: ColumnDef<INotification>[] = [
  {
    accessorKey: 'id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Id' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.id}`)
            toast.success('ID Copied to clipboard')
          }}
        >
          {formatId(row.original.id)}
        </button>
      )
    }
  },
  {
    accessorKey: 'title',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Title' />
    }
  },
  {
    accessorKey: 'body',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Body' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50 text-white'>{row.original.body}</p>
      )
    }
  },
  {
    accessorKey: 'receiver',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Receiver' />
    },
    cell: ({ row }) => {
      return row.original.send_to_all ? (
        <p className='text-foreground'>To All</p>
      ) : row.original.reciever_group ? (
        <>
          Group:{' '}
          <Link
            to={`/account-groups/${row.original.reciever_group.id}`}
            className='text-primary underline'
          >
            {row.original.reciever_group?.name}
          </Link>
        </>
      ) : row.original.receiver_user ? (
        <UserDetailsModal user={row.original.receiver_user} />
      ) : (
        <p className='text-foreground/50'>-</p>
      )
    }
  },
  {
    accessorKey: 'sender',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Sender' />
    },
    cell: ({ row }) => {
      return (
        <UserCard
          name={row.original.sender.name}
          nameClassName='text-white'
          avatar={row.original.sender.avatar}
        />
      )
    }
  },
  {
    accessorKey: 'type',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Type' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50 text-white'>{row.original.type}</p>
      )
    }
  },
  {
    accessorKey: 'created_at',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Created At' />
    },
    cell: ({ row }) => {
      const date = new Date(row.original.created_at)
      if (date.toString() === 'Invalid Date') {
        return <p className='text-foreground/50'>-</p>
      }

      return <p className='text-foreground/50'>{format(date, 'yyyy.MM.dd')}</p>
    }
  }
]
