import { useQuery } from '@tanstack/react-query'
import { format } from 'date-fns'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import ProtectedComponent from '@/components/Protected'
import UserCard from '@/components/features/UserCard'
import { Button } from '@/components/ui/Button'
import AnimatedLoader from '@/components/ui/Loader'
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/Sheet'

import { IUser } from '@/types/account/user.interface'
import { KycStatus } from '@/types/enums'

import { returnKycIcon } from '@/utils/return-kyc-icon'

import UserActions from './Actions'
import { Section } from './Section'
import api from '@/api'
import { cn } from '@/utils'

interface Props {
  user: IUser
  fromOtherPage?: boolean
}

const UserDetailsModal: React.FC<Props> = ({ user, fromOtherPage = false }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const {
    data: userData,
    isLoading,
    error
  } = useQuery({
    queryKey: ['user', user.id],
    queryFn: async () => {
      const resp = await api<IUser>(`/admin/users/${user.id}`)

      return resp.data
    },
    enabled: isOpen
  })

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger asChild>
        {fromOtherPage ? (
          <UserCard
            name={user.display_name || `@${user.username}`}
            nameClassName='text-foreground'
            avatar={user.avatar || ''}
            onPress={() => setIsOpen(true)}
          />
        ) : (
          <Button variant={'edit'}>Edit</Button>
        )}
      </SheetTrigger>
      <SheetContent className='h-screen w-full border-solid border-gray500/50 bg-gray800 sm:max-w-lg '>
        <section className='flex h-[calc(100svh-100px)] w-full flex-col gap-2 overflow-y-auto p-4 md:gap-4 md:p-6'>
          {isLoading ? (
            <AnimatedLoader />
          ) : error ? (
            <div className='flex h-full flex-col items-center justify-center gap-1'>
              <p className='text-destructive'>
                An error occurred while fetching the user data.
              </p>
              <p>{error.message}</p>
            </div>
          ) : !userData ? (
            <div className='flex h-full flex-col items-center justify-center gap-1'>
              <p className='text-destructive'>No data found for this user.</p>
            </div>
          ) : (
            <>
              <Section
                title='Details'
                avatar={userData.avatar || undefined}
                options={[
                  {
                    label: 'Display Name',
                    rightContent: userData.display_name || '-'
                  },
                  { label: 'Username', rightContent: `@${userData.username}` },
                  { label: 'Email', rightContent: userData.email },
                  {
                    label: 'KYC Status',
                    rightContent: (
                      <p
                        className={cn(
                          'flex items-center gap-1 text-sm font-semibold text-lime500',
                          {
                            'text-destructive':
                              userData.kyc_status === KycStatus.FAILED,
                            'text-orange500':
                              userData.kyc_status === KycStatus.STARTED,
                            'text-foreground/50':
                              userData.kyc_status === KycStatus.UNVERIFIED
                          }
                        )}
                      >
                        {returnKycIcon(userData.kyc_status, 'size-5')}
                        {userData.kyc_status}
                      </p>
                    )
                  },
                  {
                    label: 'Membership Type',
                    rightContent:
                      userData.membership_type === 'REGULAR'
                        ? 'Regular'
                        : 'Satoshi Plus'
                  },
                  {
                    label: 'Date Joined',
                    rightContent: format(userData.created_at, 'yyyy.MM.dd')
                  }
                ]}
              />
              {userData?.ip_addresses && userData.ip_addresses.length > 0 && (
                <Section
                  title='IP Addresses'
                  options={[
                    {
                      label: 'IP Addresses',
                      selfStart: true,
                      rightContent: (
                        <div className='flex flex-col items-end gap-1'>
                          {userData.ip_addresses.map((ip, index) => (
                            <p
                              key={index}
                              className={cn(
                                'text-[14px] font-semibold text-green-500',
                                {
                                  'text-destructive': ip.is_blocked
                                }
                              )}
                            >
                              {ip.value}
                            </p>
                          ))}
                        </div>
                      )
                    }
                  ]}
                />
              )}
              <ProtectedComponent permissionSlug='users_view'>
                <UserActions user={userData} />
                <Link
                  to={`/users/${userData.id}`}
                  className='w-full rounded-full bg-primary/10 py-2 text-center text-lg font-bold text-primary transition-all'
                >
                  View Full Details
                </Link>
              </ProtectedComponent>
            </>
          )}
        </section>
      </SheetContent>
    </Sheet>
  )
}

export default UserDetailsModal
